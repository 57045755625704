<template>
  <div class="container-fluid slide-container p-0" >
    <Splide :options="{ perPage: 1, arrows: false, }">
      <SplideSlide v-for="(slide, index) in slides" v-bind:key="index"

      >
        <div v-if="!$isMobile()" class="slide-with-background"
          v-bind:style="{background: 'url('+getSlideImage(slide)+') no-repeat center center',backgroundSize: 'cover', height: '35vw'}"
        >
          <div v-if="slide.title_image" class="title-background" :style="{backgroundImage: 'url('+slide.title_image+')'}"></div>
          <div class="play-trailer"  v-if="slide.video">
            <img @click="openVideo(slide)" src="@/assets/img/trailer-slider.svg" width="150px" alt="">
          </div>
        </div>
        <div v-else>
          <img class="w-100 mb-5" :src="getSlideImage(slide)" :alt="slide.title">
          <button v-if="slide.video" class="btn btn-lg text-uppercase btn-outline-primary w-75 btn-outline-light-blue mx-auto d-block mt-3 mb-5" @click="openVideo(slide)">
            <i class="bi bi-play-fill playicon"></i> {{ $t("Ver trailer") }}
          </button>
        </div>


      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
export default {
  name: "HomeSliders",
  props:{
    slides: {
      type: Array,
      default: function(){
        return []
      }
    }
  },
  computed:{
    currentSlide: function(){
      return this.slides[this.current];
    }
  },
  data(){
    return {
      current: 0
    }
  },
  methods:{
    // eslint-disable-next-line no-unused-vars
    getSlideImage: function(slide){
      let width = window.innerWidth;
      let image = this.getDefaultImage(slide);
      if(width > 1200){
        image = slide.image_xl ?? image; 
      } else if(width > 992){
        image = slide.image_lg ?? image; 
      } else if(width > 768){
        image = slide.image_md ?? image; 
      }

      return image
    },
    getDefaultImage(slide){
      if(slide.image_sm){
        return slide.image_sm;
      }
      if(slide.image_xl){
        return slide.image_xl;
      }

      if(slide.image_lg){
        return slide.image_lg;
      }
      
      if(slide.image_md){
        return slide.image_md;
      }
      
    },
    hash: function(string){
      return String(string).toHash()
    },
    slideStyleGenerator: function(slide){
      return {
        background: 'url('+slide.image_xl+') no-repeat center center',
        backgroundSize: 'cover !important'
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.slide-container{

  .slide-with-background{
    //height: 800px;
    //max-height: 80vh;
    .play-trailer{
      position: absolute;
      bottom: 2.5em;
      width: 100%;
      text-align: center;
      z-index: 100;
      cursor: pointer;
    }
    .title-background{
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 90%;
      height: 25%;
      z-index: 100;
      bottom: 5em;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .slide-background{
    position: relative;
    background: $dark-blue;
  }
  .home-feat-slider{
    //height: 700px;
    background-size: cover;
    width: 100vw;
    .imgplacesize{
      width: 100%;
      opacity: 0;
    }
  }
  .dot-button{
    outline: none;
    &:hover{
      color: $light-blue;
    }
    &.focus,&:focus{
      box-shadow: none;
    }
    &.active{
      color: $light-blue;
    }
  }
  .slider-controller{
    position: absolute;
    background: linear-gradient(0deg, $dark-blue 0%, rgba(0,0,0,0) 80%);
    width: 100%;
    padding-top: 100px;
    padding-right: 30px;
    height: 150px;
    z-index: 99;
    bottom: 0;
    .play-trailer{
      position: absolute;
      bottom: 4em;
      width: 100%;
      text-align: center;
      z-index: 100;
    }
  }

}

.splide__pagination__page{
  background: white;
  opacity: 1;

  &.is-active{
    background: $light-blue !important;
    transform: scale(1);
    opacity: 1;
  }
}
</style>