<template>
  <div class="container programming">
    <div class="col-12 mx-auto">
      <div class="row programming-grid-header pt-3 pb-1" >
        <div class="col-md-8 px-0">
          <span class="title">
            {{ $t("Esta noche en viendomovies") }}
          </span>
        </div>
        <div v-if="!$isMobile()" class="col-md-4 px-0">
          <router-link class="read-more" :to="{name: 'programming'}">
            {{ $t("Ver programación completa") }} >
          </router-link>
        </div>
      </div>
      <div class="row programming-grid-body py-3" v-if="programming.length > 0">
        <div  v-for="(program, index) in programming.slice(0,3)" :key="index" class="col-md programming-grid-prog list-mobile">
          <router-link  :to="{name: 'movie', params: {slug: program.movie.slug}}">
            <span class="asset">
              <span class="programming-asset font-weight-bold">
                {{program.movie.title.toUpperCase()}}
                </span>
                <span class="programming-asset">
                {{program.start_time.toProgrammingDate(locale)}}
              </span>
            </span>
          </router-link>
        </div>
      </div>
      <div class="row" v-if="$isMobile()" >
        <div class="col px-0">
          <router-link class="read-more pb-3 text-left" :to="{name: 'programming'}">
            {{ $t("Ver programación completa") }} >
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HomeProgramming",
  props: {
    programming : {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters(["locale"])
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.list-mobile{
  margin: 1em 0;

}

.programming{
  .title{
    text-transform: uppercase;
    font-size: .8em;
    font-weight: bold;
    color: $dark-blue;
  }
  .programming-grid-body{
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }
  .asset{

    text-transform: uppercase;
    font-size: .8em;
    font-weight: normal;
    color: $dark-blue;
    transition: all 300ms ease-in-out;
    &:hover{
      color: $light-blue;
    }
    .programming-asset{
      display: block;
    }
  }
  .read-more{
    text-align: right;
    width: 100%;
    display: block;
    transition: color 300ms ease-in-out;
    &:hover{
      color: $dark-blue;
    }
  }
}
</style>