<template>
  <div>
<!-- Featured Carousel   -->
    <home-sliders  v-bind:slides="slides" v-if="slides.length > 0"></home-sliders>
    <div class="container-fluid programming-grid" id="programming" v-if="programming.length > 0" >
      <home-programming :programming="programming"></home-programming>
    </div>

    <template >
      <div
          :class="($isMobile() ? 'container-fluid' : 'container')"
          v-for="(section,si) in sections"
          v-bind:key="si"
      >
        <app-section :title="section.title"></app-section>
        <home-movies v-if="section.type === 'movie' && section.layout === 'carousel'" :movies="section.content"></home-movies>
        <home-preview v-else-if="section.type === 'movie' && section.layout === 'grid'" :movies="section.content"></home-preview>
<!--        <home-on-demand-movies v-if="section.type === 'movie' && section.element_layout === 'info'" :movies="section.content"></home-on-demand-movies>-->
        <home-award v-else-if="section.type === 'award'" :awards="section.content"></home-award>
        <home-news v-else-if="section.type === 'new'" :news="section.content"></home-news>

        <div class="row" v-if="section.type === 'award' && debug">
        <pre class="col-6" style="color: wheat">
          {{section.content}}
        </pre>
        <pre class="col-6" style="color: wheat">
          {{awardsAmerica}}
        </pre>
        </div>

      </div>
    </template>

  </div>
</template>

<script>

import AppSection from "@/components/AppSection";
import HomeAward from "@/components/home/HomeAward";
import HomeNews from "@/components/home/HomeNews";
import HomeMovies from "@/components/home/HomeMovies";
import HomeProgramming from "@/components/home/HomeProgramming";
import {mapGetters} from "vuex";
import HomePreview from "../components/home/HomePreview";
import HomeSliders from "../components/home/HomeSliders";
// import HomeOnDemandMovies from "../components/home/HomeOnDemandMovies";
export default {
  name: "Home",
  components: {
    // HomeOnDemandMovies,
    HomeSliders,
    HomeProgramming,
    HomeMovies,
    HomeNews,
    HomePreview,
    HomeAward,
    AppSection
  },
  computed:{
    ...mapGetters([
      'slides',
      'sections',

      'programming',
      'releases',
      'onDemand',
      'awardsAmerica',
      'awardsEurope',
      'news'
    ])
  },
  data(){
    return {
      debug: false
    }
  }
}
</script>