<template>

  <movie-carousel :movies="movies" ></movie-carousel>
</template>

<script>
import MovieCarousel from "../modules/MovieCarousel";
export default {
  name: "HomeMovies",
  components: {MovieCarousel},
  props: {
    movies : {
      required : true,
      type : Array
    }
  },
  computed:{
    movieFiltered: function(){
      let start = this.position;
      let end = (this.position + this.items);
      let max = this.movies.length;
      let limit = (max - this.items);
      end = (end > max ? max : end);
      start = (start > limit ? limit : start);
      return this.movies.slice(start, end)
    }
  },
  data(){
    return {
      items: 4,
      position: 0
    }
  }
}
</script>

<style scoped>
  .movie-tile {

  }
</style>