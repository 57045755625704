<template>
  <div class="movie-grid container">
    <div class="row mb-3" v-for="(movie, i) in movies" v-bind:key="i">
        <div class="col-md-6">
          <img class="w-100" :src="movie.horizontal_poster" :alt="movie.title">
        </div>
      <div class="col-md-6">
        <router-link class="a-no-decoration" :to="{name: 'movie', params: {slug: movie.slug}}">
          <h4 class="text-capitalize my-2 text-white">{{movie.title}}</h4>
          <p class="text-muted" v-html="movie.short_description"></p>
        </router-link>
        <router-link class="text-muted text-capitalize" :to="{name: 'movie', params: {slug: movie.slug}}">
          {{ $t("Ver Más") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePreview",
  props: {
    movies : {
      required : true,
      type : Array
    }
  },
}
</script>

<style scoped>

</style>