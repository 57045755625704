<template>
  <div class="awards-container">
    <Splide :options="{
                        rewind: false,
                        loop:false,
                        arrows : !$isMobile(),
                        perPage: ($isMobile() ? 2 :4),
                        pagination: false
    }">
      <SplideSlide class="award-card col" v-bind:class="{'award-card-mobile':$isMobile()}" v-for="(award,awi) in awards" v-bind:key="awi">

          <router-link :to="{name: 'award', params: {slug: award.slug}}">

            <div class="award-element">
              <div class="award-box">
                  <div class="award-logo" v-bind:style="awardBox(award)"></div>
              </div>
              <p class="font-weight-bold small text-white pt-3 pb-1 text-white title">{{ award.name.toUpperCase() }}</p>
              <!-- <span class="font-weight-bold small text-white clearfix">{{ award.country }}</span> -->
              <router-link class="small clearfix text-muted" :to="{name: 'award', params: {slug: award.slug}}">
              {{$t("Ver Más")}}
              </router-link>
            </div>
            
          </router-link>
      </SplideSlide>
    </Splide>

  </div>
</template>

<script>
export default {
  name: "HomeAward",
  props: {
    awards: {
      required: true,
      type: Array
    }
  },
  methods:{
    awardBox: (award)=>{
      return {
        background: 'url("'+award.icon+'") no-repeat center center / contain'
      }
    }
  },
  computed:{
    awardsFiltred: function(){
      var size = 5;
      var awards = [];
      for (var i=0; i< this.awards.length; i+=size) {
        awards.push(this.awards.slice(i,i+size));
      }
      return awards;
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.award-card{
  &.award-card-mobile{
    max-width: 90vw !important;
    height: 80vw;
  }
  max-width: 20vw !important;
  margin-bottom: 2em;
  transition: all 300ms ease-in-out;
  &:hover{
    //transform: scale(1.3);
    //z-index: 1;
  }
 
  .award-element{
      margin: auto;
      max-width: 200px;
      .award-box{
        width: 40vw;
        height: 40vw;
        padding: .5em;
        border: 1px solid white;
        .award-logo{
          width: 30vw;
          max-width: 100%;
          height: 20vw;
          margin: 8vw auto;
        }
      }
      .title{
        height: 4em;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .award-img{
        width: 100%;
        border-radius: 5px;
      }
  }
}

@media (min-width: 481px)  {
  .awards-container{

    height: 300px !important;
    .award-card{
      .award-box{
        width: 200px !important;
        height: 200px !important;
        padding: .5em;
        margin: 10px;
        border: 1px solid white;
        .award-logo{
          width: 200px !important;
          max-width: 100%;
          height: 150px !important;
          margin: 15px auto !important;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1000px)  {
  .awards-container{

    height: 300px !important;
    .award-card{
      .award-box{
        width: 150px !important;
        height: 200px !important;
        padding: .5em;
        border: 1px solid white;
        .award-logo{
          width: 200px !important;
          max-width: 100%;
          height: 150px !important;
          margin: 15px auto !important;
        }
      }
    }
  }
}

</style>